import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {SendMailService} from '../../service/send-mail.service';
import {ReCaptchaV3Service} from '../../service/recaptcha_v3.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ScriptService} from '../../service/script.service';

@Component({
  selector: 'app-doctors',
  animations: [
    trigger('showHide', [
      state('visible', style({
        // display: 'block',
        opacity: 1,
      })),
      state('hidden', style({
        // display: 'none',
        opacity: 0,
      })),
      transition('hidden => visible', [
        animate('1s')
      ]),
      transition('visible => hidden', [
        animate('0.5s')
      ])
    ])
  ],
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.css']
})
export class DoctorsComponent implements OnInit {
  minDate = new Date();
  maxDate = new Date();

  doctors = [
    {
      name: 'Mariana Constantinescu',
      specialty: 'Medic Dentist, Masterat Implantologie',
      program_html: '10:00 - 19:00<br>' +
        '10:00 - 19:00<br>' +
        '10:00 - 19:00<br>' +
        '10:00 - 19:00<br>' +
        '10:00 - 19:00<br>' +
        'Inchis<br>' +
        'Inchis ',
      image: 'mariana.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: true,
    },
    {
      name: 'Andra Constantinescu',
      specialty: 'Medic Specialist Parodontologie',
      program_html: '9:00-13:00/19:00-20:00<br>' +
        '9:00-13:00/19:00-20:00<br>' +
        '9:00-13:00/19:00-20:00<br>' +
        '9:00-13:00/19:00-20:00<br>' +
        '9:00-13:00/19:00-20:00<br>' +
        'Inchis<br>' +
        'Inchis',
      image: 'andra.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: true,
    },
    {
      name: 'Paula Constantinescu',
      specialty: 'Medic Specialist Chirurgie Dento Alveolara',
      program_html: '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        'Inchis<br>' +
        'Inchis',
      image: 'doctor.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: true,
    },
    {
      name: 'Borsova Monica',
      specialty: 'Medic Ortodont',
      program_html: 'La programare<br>' +
          'La programare<br>' +
          'La programare<br>' +
          'La programare<br>' +
          'La programare<br>' +
          'Inchis<br>' +
          'Inchis',
      image: 'doctor.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: false,
    },
    {
      name: 'George Stuparu',
      specialty: 'Medic Specialist Chirurgie BMF',
      program_html: 'La programare<br>' +
        'La programare<br>' +
        'La programare<br>' +
        'La programare<br>' +
        'La programare<br>' +
        'Inchis<br>' +
        'Inchis',
      image: 'george-stuparu.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: false,
    },
    // {
    //   name: 'Godini Felicia',
    //   specialty: 'Asistenta',
    //   program_html: '13:00 - 19:00<br>' +
    //       '13:00 - 19:00<br>' +
    //       '13:00 - 19:00<br>' +
    //       '13:00 - 19:00<br>' +
    //       '13:00 - 19:00<br>' +
    //       'Inchis<br>' +
    //       'Inchis',
    //   image: 'felicia.jpg',
    //   submitted: false,
    //   backEndResponse: '',
    //   loading: false,
    //   booking: false,
    // },
    {
      name: 'Roman Denisa',
      specialty: 'Asistenta',
      program_html: '13:00 - 19:00<br>' +
          '13:00 - 19:00<br>' +
          '13:00 - 19:00<br>' +
          '13:00 - 19:00<br>' +
          '13:00 - 19:00<br>' +
          'Inchis<br>' +
          'Inchis',
      image: 'doctor.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: false,
    },
    {
      name: 'Ionescu Maria',
      specialty: 'Asistenta',
      program_html: '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        '13:00 - 19:00<br>' +
        'Inchis<br>' +
        'Inchis',
      image: 'doctor.jpg',
      submitted: false,
      backEndResponse: '',
      loading: false,
      booking: false,
    },
  ];

  constructor(private sendMail: SendMailService, private reCaptchaV3Service: ReCaptchaV3Service) { }

  afterOwlDoctorsFor() {
    const owl1 = $('#owl-doctors');

    owl1.owlCarousel({
      items: 3,
      itemsDesktop: [1000, 3],
      itemsDesktopSmall: [900, 2],
      itemsTablet: [600, 1],
      pagination: false,
    });

    ScriptService.fancybox1Update();
  }

  bookFormSubmit(f: NgForm) {
    const index = f.form.value.index;
    const doctor = f.form.value['form-doctor'];

    this.doctors[index].submitted = true;
    this.doctors[index].backEndResponse = '';
    this.doctors[index].loading = true;

    if (f.form.valid) {
      this.reCaptchaV3Service.execute('6Lc-yp8UAAAAAEean5n3GsFQu9w9CEaBQvQkl9If', 'doctor_form', (token) => {
        this.sendMail.sendMail(f.form.value, token).subscribe(
          data => {
            // console.log(data);
            this.doctors[index].submitted = false;
            this.doctors[index].backEndResponse = 'success';
            this.doctors[index].loading = false;
            f.reset();
            f.form.value.index = index;
            f.form.value['form-doctor'] = doctor;
          },
          error => {
            // console.log(error.error);
            this.doctors[index].submitted = false;
            this.doctors[index].backEndResponse = 'error';
            this.doctors[index].loading = false;
          }
        );
      }, {
        useGlobalDomain: false,
        justLoadScript: false
      });
    } else {
      this.doctors[index].loading = false;
    }

    // console.log(f.form.valid);
    // console.log(f.form.value);
  }

  ngOnInit() {
    this.maxDate.setDate(this.minDate.getDate() + 14);
  }

}
